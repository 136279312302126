import React, { useState, createContext } from 'react';
import styles from './index.module.scss';
import { IServicePlan, ServicePlanData } from './store';
import { ServicePlanCard, ServicePopup } from './components';
import { SvgIcon, TooltipDisclaimer } from '@ui-elements';
interface ServicePlanContextProps {
  popupTitle: string;
  setPopupTitle: React.Dispatch<React.SetStateAction<string>>;
  popupSelectedItem: ServicePlanData[] | undefined;
  setPopupSelectedItem: React.Dispatch<
    React.SetStateAction<ServicePlanData[] | undefined>
  >;
  isPopupOpen: boolean;
  setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ServicePlanCardPopupContext =
  createContext<ServicePlanContextProps>({
    popupTitle: '',
    setPopupTitle: () => {},
    popupSelectedItem: undefined,
    setPopupSelectedItem: () => {},
    isPopupOpen: false,
    setIsPopupOpen: () => {},
  });

const ServicePlanContent = React.memo((props: IServicePlan) => {
  const [popupTitle, setPopupTitle] = useState('');
  const [popupSelectedItem, setPopupSelectedItem] = useState<
    ServicePlanData[] | undefined
  >([]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const value = {
    popupTitle,
    setPopupTitle,
    popupSelectedItem,
    setPopupSelectedItem,
    isPopupOpen,
    setIsPopupOpen,
  };

  const handleOnClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <ServicePlanCardPopupContext.Provider value={value}>
      <ServicePopup
        title={popupTitle}
        selectedItem={popupSelectedItem}
        isPopupOpen={isPopupOpen}
        onClosePopup={handleOnClosePopup}
      />
      <div id={`cid${props.id}`} className={styles.spContainer}>
        <div className={styles.spContentContainer}>
          <div className={styles.spHeading}>
            <TooltipDisclaimer
              followCursor
              disclaimer={props.headerTooltip}
              className={styles.parentTooltipContainer}
              contentClassName={styles.firstDivTooltipContainer}
              triggerClassName={styles.triggerClassName}
              tooltipClassName={styles.tooltipContainer}
            >
              <span
                className={styles.spHeadingText}
                dangerouslySetInnerHTML={{ __html: props.heading || '' }}
              />
            </TooltipDisclaimer>
          </div>
          <div
            className={styles.spHeadingDescription}
            dangerouslySetInnerHTML={{
              __html: props.headingDescription as string,
            }}
          />
          <div className={styles.spTextContents}>
            <div className={styles.spTextContentsLeft}>
              {props?.servicePlanText
                ?.slice(0, Math.round(props?.servicePlanText?.length / 2))
                .map((item, index) => (
                  <div className={styles.spTextContent} key={index}>
                    <div
                      className={styles.icon}
                      style={{
                        boxShadow: `0px 0px 1px 1px "#FFFFFF"`,
                      }}
                    >
                      <SvgIcon type="check" size={0.8} color="#FFFFFF" />
                    </div>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item.trim(),
                      }}
                    />
                  </div>
                ))}
            </div>

            <div className={styles.spTextContentsRight}>
              {props?.servicePlanText
                ?.slice(
                  Math.round(props?.servicePlanText?.length / 2),
                  props?.servicePlanText?.length
                )
                .map((item, index) => (
                  <div className={styles.spTextContent} key={index}>
                    <div
                      className={styles.icon}
                      style={{
                        boxShadow: `0px 0px 1px 1px "#FFFFFF"`,
                      }}
                    >
                      <SvgIcon type="check" size={0.8} color="#FFFFFF" />
                    </div>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item.trim(),
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* service plan with pop up */}
        <div className={styles.spPlanContainer}>
          <ServicePlanCard cards={props.servicePlans} />
        </div>
      </div>
    </ServicePlanCardPopupContext.Provider>
  );
});

export default ServicePlanContent;
